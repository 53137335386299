import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { IoLocation } from "react-icons/io5";
import SlashInput from "./SlashInput";
import UserService from "../service/user.service";
import Toaster from "./toaster";
import Loader from "../../assets/loader.gif";
import { LocalData } from "./Common";
import { AiFillCamera } from "react-icons/ai";
import Profile from "../../assets/profile.jpg";
import { AuthContext } from "../../context/authContext";
import VendorService from "../service/vendor.service";
import AvatarIcon from "./Avatar";
import { MdArrowBack } from "react-icons/md";

const EditBothUserProfile = () => {
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);
  const payyroStore = LocalData();
  const localRole = payyroStore.role;
  const [selectedImage, setSelectedImage] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(
    (localRole === "Get Help" ||
      role === "Get Help" ||
      localRole === "Give Help" ||
      role === "Give Help") &&
      true
  );

  const validationSchema = yup.object().shape({
    name:
      localRole === "Vendor" || role === "Vendor"
        ? yup.string().required("Name is required")
        : yup.string(),
    firstName:
      localRole === "Vendor" || role === "Vendor"
        ? yup.string()
        : yup.string().required("First name is required"),
    lastName:
      localRole === "Vendor" || role === "Vendor"
        ? yup.string()
        : yup.string().required("Last name is required"),
    email: yup.string().required("Email is required"),
    address: yup.string().required("Address is required"),
    companyname:
      localRole === "Vendor" || role === "Vendor"
        ? yup.string().required("Company Name is required")
        : yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      companyname: "",
      latestProfileImage: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsSubmitLoading(true);
      const {
        name,
        firstName,
        lastName,
        email,
        address,
        companyname,
        latestProfileImage,
      } = values;

      const formData = new FormData();
      if (localRole === "Vendor" || role === "Vendor") {
        formData.append("name", name);
      }
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("latestProfileImage", latestProfileImage);
      if (companyname !== "") {
        formData.append("companyname", companyname);
      }

      const localObject = {
        ...payyroStore,
        name: localRole === "Vendor" || role === "Vendor" ? name : firstName,
        email: email,
        userName: firstName ? firstName : name,
        address: address ? address : "",
      };

      localStorage.setItem("payyro-store", JSON.stringify(localObject));

      try {
        let response;
        if (
          localRole === "Get Help" ||
          role === "Get Help" ||
          localRole === "Give Help" ||
          role === "Give Help"
        ) {
          response = await UserService.updatePersonalDetails(formData);
        } else {
          response = await VendorService.updatePersonalDetails(formData);
        }
        Toaster("success", response.data.message);
        navigate(
          role === "Give Help" || localRole === "Give Help"
            ? "/give-help/user-profile"
            : role === "Get Help" || localRole === "Get Help"
            ? "/get-help/user-profile"
            : "/vendor/profile"
        );
      } catch (error) {
        Toaster("error", error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    },
  });

  const getOwnProfile = async () => {
    try {
      let response;
      if (
        localRole === "Get Help" ||
        role === "Get Help" ||
        localRole === "Give Help" ||
        role === "Give Help"
      ) {
        response = await UserService.getOwnProfile();
      } else {
        response = await VendorService.getVendorOwnProfile();
      }
      const result = response.data.data;

      formik.setFieldValue("name", result.name);
      formik.setFieldValue("companyname", result.company);
      formik.setFieldValue("firstName", result.firstName);
      formik.setFieldValue("lastName", result.lastName);
      formik.setFieldValue("email", result.email);
      formik.setFieldValue("address", result.address);
      formik.setFieldValue("latestProfileImage", result.latestProfileImage);
      setSelectedImage(result.latestProfileImage);
      setIsLoading(false);
    } catch (error) {
      Toaster("error", error.response.data.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChnage = (e) => {
    const file = e.target.files[0];
    processImages(file);
  };

  const processImages = (file) => {
    formik.setFieldValue("latestProfileImage", file);
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    processImages(files);
  };

  useEffect(() => {
    getOwnProfile();
  }, []);

  return (
    <div>
      {/* <div className="dashboard-part flex lg:gap-9 min-h-[calc(100vh-195px)]"> */}
      <div className="dashboard-part flex lg:gap-9">
        <div className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px]">
          <div className="b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5 flex items-center gap-4">
            <span className="cursor-pointer" onClick={() => navigate(-1)}>
              <MdArrowBack size={20} />
            </span>
            <h5 className="tab:text-base text-sm">Edit Profile</h5>
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <img src={Loader} alt="Loader" className="h-16 my-3" />
            </div>
          ) : (
            <>
              <div className="px-4 tab:px-26">
                <div
                  className="block tab:flex tab:text-start text-center justify-center tab:justify-start items-center  tab:gap-9 b-b border-primary/20 py-5 tab:py-26"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => handleDrop(e, "image")}
                >
                  <div className="relative tab:h-[120px] tab:w-[120px] h-20 w-20 mx-auto tab:mx-0 mb-5 tab:mb-0 avatar">
                    {selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="Profile"
                        className="h-full w-full rounded-full object-cover"
                      />
                    ) : (
                      <AvatarIcon
                        size={120}
                        className="rounded-full"
                        name={formik.values.name}
                      />
                    )}
                    <label
                      htmlFor="profile-upload"
                      className="absolute bottom-1 -right-1  h-[22px] w-[22px] tab:h-9 tab:w-9 rounded-full flex justify-center items-center bg-primary cursor-pointer"
                    >
                      <AiFillCamera className="text-white text-xl" />
                      <input
                        id="profile-upload"
                        onChange={handleFileChnage}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                  <div>
                    <h6 className="tab:text-lg text-base">Profile Photo</h6>
                    <p className="text-sm">Browse or Drag image to upload</p>
                  </div>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-4 p-4 tab:p-26">
                  <h6 className="tab:text-base text-sm">Personal Details</h6>
                  <div className="grid grid-cols-1 tab:grid-cols-2 flex-col gap-4">
                    {!(localRole === "Vendor" || role === "Vendor") && (
                      <div>
                        <label>First Name*</label>
                        <SlashInput
                          name="firstName"
                          type="text"
                          placeholder=""
                          formik={formik}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="valierror">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                    )}
                    {!(localRole === "Vendor" || role === "Vendor") && (
                      <div>
                        <label>Last Name*</label>
                        <SlashInput
                          name="lastName"
                          type="text"
                          placeholder=""
                          formik={formik}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="valierror">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                    )}
                    {(localRole === "Vendor" || role === "Vendor") && (
                      <div>
                        <label>Name*</label>
                        <SlashInput
                          name="name"
                          type="text"
                          placeholder=""
                          formik={formik}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="valierror">{formik.errors.name}</div>
                        ) : null}
                      </div>
                    )}
                    <div>
                      <label>Email*</label>
                      <SlashInput
                        name="email"
                        type="email"
                        placeholder=""
                        formik={formik}
                        path="edit-profile"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="valierror">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>
                  {localRole === "Vendor" || role === "Vendor" ? (
                    <div className="grid grid-cols-1 tab:grid-cols-2 flex-col gap-4">
                      <div>
                        <label>Company Name*</label>
                        <SlashInput
                          name="companyname"
                          type="text"
                          placeholder=""
                          formik={formik}
                        />
                        {formik.touched.companyname &&
                        formik.errors.companyname ? (
                          <div className="valierror">
                            {formik.errors.companyname}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label>Address*</label>
                        <div className="relative">
                          <SlashInput
                            formik={formik}
                            name="address"
                            type="text"
                            placeholder=""
                          />
                          <div className="absolute top-1/2 right-3 -translate-y-1/2">
                            <IoLocation className="text-primary/50" />
                          </div>
                        </div>
                        {formik.touched.address && formik.errors.address ? (
                          <div className="valierror">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label>Address*</label>
                      <div className="relative">
                        <SlashInput
                          formik={formik}
                          name="address"
                          type="text"
                          placeholder=""
                        />
                        <div className="absolute top-1/2 right-3 -translate-y-1/2">
                          <IoLocation className="text-primary/50" />
                        </div>
                      </div>
                      {formik.touched.address && formik.errors.address ? (
                        <div className="valierror">{formik.errors.address}</div>
                      ) : null}
                    </div>
                  )}
                </div>

                <div className=" b-b border-primary/20 mb-26"></div>
                <div className="flex justify-end tab:py-5 tab:px-26 py-4 px-4">
                  {isSubmitLoading ? (
                    <div className="flex justify-end items-center h-[49.17px] w-[170.91px] ">
                      <button
                        type="submit"
                        className="btn-big btn-outline-primary !p-0 w-full h-full flex justify-center items-center hover:bg-transparent"
                        disabled
                      >
                        <img src={Loader} alt="Loader" className="w-8 h-8" />
                      </button>
                    </div>
                  ) : (
                    <button type="submit" className="btn-big btn-primary">
                      Update Profile
                    </button>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default EditBothUserProfile;
