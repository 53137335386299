import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { RxSlash } from "react-icons/rx";
import { TiHome } from "react-icons/ti";
import AmountInput from "../../common/AmountInput";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import stripeImg from "../../../assets/svg/stripe.svg";
import location from "../../../assets/svg/location.svg";
import SlashInput from "../../common/SlashInput";
import { scrollToTop } from "../../common/constatnts";
import HelpService from "../../service/help.service";
import Toaster from "../../common/toaster";
import AvatarIcon from "../../common/Avatar";
import moment from "moment";
import Loader from "../../../assets/loader.gif";
import ProgressBar from "../../common/ProgressBar";
import {
  HandleChangeAmount,
  LocalData,
  NumberWithCommas,
} from "../../common/Common";
import StripeService from "../../service/stripe.service";
import { Country, State } from "country-state-city";
import Select from "react-select";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

const UserGiveHelpPayment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const localData = LocalData();
  const [data, setData] = useState([]);
  const [ispaymentMethod, setIsPaymentMethod] = useState("card");
  const [isLoading, setIsLoading] = useState(true);
  const [locationAddress, setLocationAddress] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [requestedAmount, setRequestedAmount] = useState(null);

  /* useEffect(() => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          Toaster("error", error.message);
        }
      );
    }
  }, []); */

  const getAddressFromCoordinates = (lat, lng) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.address) {
          const addressObject = {
            postalCode: data.address.postcode || "",
            state: data.address.state || "",
            country: data.address.country || "",
            city: data.address.city || data.address.state_district || "",
            line:
              data.address.road ||
              data.address.neighbourhood ||
              data.address.suburb ||
              data.address.town ||
              "",
          };
          setLocationAddress(addressObject);
        } else {
          Toaster("error", "No address found for the given coordinates.");
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const submit = async (values) => {
    setIsSubmitLoading(true);
    try {
      if (ispaymentMethod === "card") {
        const intentBody = {
          amount: values.amount * 100,
          currency: "usd",
          customerName: localData?.name,
          customerAddress: {
            line: values.line,
            postalCode: values.postalCode,
            city: values.city,
            state: selectedState?.label,
            country: selectedCountry?.label,
          },
        };

        const intentResponse = await StripeService.createCardPaymentIntent(
          intentBody
        );

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: values.cardname,
          },
        });

        if (intentResponse) {
          const body = {
            helpRequestId: id,
            amount: values.amount,
            customerId: localData?.customerId,
            paymentMethod: "Card",
            paymentMethodId: paymentMethod.id,
            paymentIntentId: intentResponse.data.clientSecret.id,
            isSavedCard: values.isSavedCard,
            isPreferred: values.isPreferred,
          };

          const response = await HelpService.giveHelpPayment(body);

          if (response.data.status === 200) {
            const { error, paymentIntent } = await stripe.confirmCardPayment(
              intentResponse.data.clientSecret.client_secret,
              {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                  billing_details: {
                    name: values.cardname,
                  },
                },
              }
            );

            Toaster("success", response.data.message);
            formik.resetForm();
            navigate("/give-help/help");
          }
        }
      } else if (ispaymentMethod === "bank") {
        const intentBody = {
          amount: values.amount * 100,
          currency: "usd",
          paymentMethodTypes: "us_bank_account",
          accountNumber: String(values.accountNo)
            .replace(/^0+/, "")
            .padStart(12, "0"),
          routingNumber: values.routingNumber,
          accountHolderType: "individual",
          name: values.accountHolderName,
        };

        const intentResponse = await StripeService.createBankPaymentIntent(
          intentBody
        );

        const body = {
          helpRequestId: id,
          amount: values.amount,
          customerId: localData?.customerId,
          paymentMethod: "BankAccount",
          paymentMethodId: "",
          paymentIntentId: intentResponse.data.clientSecret.id,
          isSavedCard: values.isSavedCard,
          isPreferred: values.isPreferred,
        };

        const response = await HelpService.giveHelpPayment(body);

        if (response.data.status === 200) {
          const { error, paymentIntent } =
            await stripe.confirmUsBankAccountPayment(
              intentResponse.data.clientSecret.client_secret,
              {
                payment_method: {
                  type: "us_bank_account",
                  us_bank_account: {
                    account_number: String(values.accountNo)
                      .replace(/^0+/, "")
                      .padStart(12, "0"),
                    routing_number: values.routingNumber,
                    account_holder_type: "individual",
                  },
                  billing_details: {
                    name: values.accountHolderName,
                  },
                },
                return_url: "http://localhost:3001/success",
              }
            );

          if (error) {
            Toaster("error", error.message);
          } else if (paymentIntent.status === "succeeded") {
            Toaster("success", "Payment successful");
          } else {
            window.open(
              paymentIntent.next_action.verify_with_microdeposits
                .hosted_verification_url
            );
          }
        }
      }
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    // amount: yup.string().required("Amount is Required"),
    amount: yup
      .number()
      .required("Amount is Required")
      .typeError("Please enter a valid number")
      .test(
        "max",
        `Amount cannot exceed the help needed amount of ${NumberWithCommas(
          requestedAmount
        )}`,
        (value) => value <= requestedAmount
      )
      .test("min", "Amount must be greater than 0", (value) => value > 0),

    line: yup.string().required("Street is Required"),
    city: yup.string().required("City is Required"),
    state: yup.string().required("State is Required"),
    country: yup.string().required("Country is Required"),
    postalCode: yup
      .string()
      .required("Postal code is Required")
      .min(5, "Postal code must be minimun 5 Characters"),
    cardname:
      ispaymentMethod === "card" &&
      yup.string().required("Card Name is Required"),
    accountNo:
      ispaymentMethod === "bank" &&
      yup
        .string()
        .required("Account Number is Required")
        .test(
          "min",
          "Account Number must be greater than 0",
          (value) => value > 0
        ),
    routingNumber:
      ispaymentMethod === "bank" &&
      yup
        .string()
        .required("Routing Number is Required")
        .test(
          "min",
          "Routing Number must be greater than 0",
          (value) => value > 0
        ),
    accountHolderName:
      ispaymentMethod === "bank" &&
      yup.string().required("Acoount Holder Name is Required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      name: "",
      // email: "",
      // paymentmethod: "",
      cardname: "",
      cardnumber: "",
      exdate: "",
      cvv: "",
      isPreferred: false,
      isSavedCard: false,
      accountNo: "",
      routingNumber: "",
      accountHolderName: "",
      line: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });

  const getRequestDetails = async () => {
    try {
      const response = await HelpService.getHelpAcccountDetails(
        id,
        localData.id
      );
      setData(response.data.data);
      const data = response.data.data;
      setRequestedAmount(data?.requestedAmount - data?.fundedAmount);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const countryList = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      label: country.name,
    }));
    setCountries(countryList);
  }, []);

  // Fetch states whenever a country is selected
  useEffect(() => {
    if (selectedCountry) {
      const stateList = State.getStatesOfCountry(selectedCountry.value).map(
        (state) => ({
          value: state.isoCode,
          label: state.name,
        })
      );
      setStates(stateList);
      formik.setFieldValue("state", ""); // Reset state field in Formik
    }
  }, [selectedCountry]);

  useEffect(() => {
    getRequestDetails();
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  // const handleAmountChange = (e) => {
  //   const input = e.target.value;
  //   formik.setFieldValue("amount", input);
  // };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "40px",
      maxHeight: "40px",
      borderRadius: "4px",
      borderColor: state.isFocused ? "#00253f" : provided.borderColor, // Change border color on focus
      boxShadow: state.isFocused ? "0 0 0 0px #00253f" : provided.boxShadow, // Change outline color on focus
      "&:hover": {
        borderColor: "#00253f", // Optional: hover border color
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px",
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "4px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
  };

  return (
    <div className="bg-cgreen">
      <div className="grid gap-30 pt-0 pb-5 tab:pt-2 tab:pb-11 containers">
        {isLoading ? (
          <div className="flex justify-center mt-3">
            <img src={Loader} alt="Loader" className="w-14 h-14" />
          </div>
        ) : (
          <>
            <ol className="inline-flex items-center space-x-1 rtl:space-x-reverse">
              <li className="inline-flex items-center">
                <div
                  onClick={() => navigate("/")}
                  className="text-primary/50 hover:text-primary duration-200 cursor-pointer"
                >
                  <TiHome className="h-[22px] w-[22px]" />
                </div>
              </li>
              <RxSlash className="text-[#80929f]" />
              <li>
                <div className="flex items-center">
                  <div
                    onClick={() => navigate("/give-help/help")}
                    className="sm:text-base text-sm font-bold text-primary/50 hover:text-primary duration-200 cursor-pointer"
                  >
                    Help Requests
                  </div>
                </div>
              </li>
              <RxSlash className="text-primary/50" />
              <li className="font-bold text-primary">
                <div className="sm:text-base text-sm cursor-pointer">
                  Give Help
                </div>
              </li>
            </ol>
            <div className="grid grid-cols-12 gap-30 tab:gap-10">
              <div className="col-span-12 md:col-span-5 h-fit dashboard-card p-4 tab:p-26">
                <div className="grid gap-y-5">
                  <h5 className="text-base sm:text-xl">
                    Help Request to pay {data.category?.name} for{" "}
                    {data.customerName}
                  </h5>
                  <div className="flex justify-between">
                    <div className="flex justify-start items-start gap-3">
                      <div className="avatar">
                        {data.userData?.latestProfileImage ? (
                          <img
                            src={data.userData?.latestProfileImage}
                            className="sm:h-20 sm:w-20 h-[60px] w-[60px] rounded-md object-cover"
                          />
                        ) : (
                          <AvatarIcon
                            size={60}
                            className="rounded-lg"
                            name={
                              data.userData?.name || data.userData?.firstName
                            }
                          />
                        )}
                      </div>
                      <div>
                        <h5 className="sm:text-xl text-base font-normal">
                          {data.userData?.name || data.userData?.firstName}
                        </h5>
                        <div className="flex gap-2">
                          <img src={location} />
                          <span className="text-xs sm:text-sm text-gray-500 font-normal">
                            {data.userData?.address}
                          </span>
                        </div>
                      </div>
                    </div>
                    {data.userData?.isPayyroVerified && (
                      <div className="flex gap-1 h-fit items-center">
                        <RiVerifiedBadgeFill className="text-success w-3 h-3 sm:w-[14px] sm:h-[13.13px]" />
                        <h6 className="text-[10px] sm:text-xs">
                          Payyro Verified
                        </h6>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <h5 className="sm:text-xl text-base">
                        Balance: $
                        {NumberWithCommas(
                          data.requestedAmount - data.fundedAmount
                        )}
                      </h5>
                      <span className="text-xs sm:text-sm text-gray-500 font-bold">
                        Updated: {moment(data?.updatedAt).format("DD MMM YYYY")}
                      </span>
                    </div>
                    <div className="w-full py-4">
                      <ProgressBar user={data} />
                    </div>
                    <div className="flex justify-between items-center text-sm sm:text-base">
                      Original Help Request{" "}
                      <strong>${NumberWithCommas(data.requestedAmount)}</strong>
                    </div>
                    <div className="flex justify-between items-center mt-3 text-sm sm:text-base">
                      Payment Received{" "}
                      <strong>${NumberWithCommas(data.fundedAmount)}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-7 dashboard-card p-4 tab:p-26">
                <h4 className="text-base tab:text-26 b-b pb-3 tab:pb-4">
                  Additional Help Needed: $
                  {NumberWithCommas(data.requestedAmount - data.fundedAmount)}
                </h4>
                <div className="py-4 tab:pt-26">
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <h6 className="text-sm tab:text-lg">
                        Enter Your Payment Amount
                      </h6>
                      <h6 className="font-normal text-xs tab:text-sm">
                        (Payment will be sent to{" "}
                        <strong>{data.vendor.company}</strong>)
                      </h6>

                      {/* Amount */}
                      <div className="py-4 b-b tab:pb-26">
                        <label className="text-sm">Amount*</label>
                        <AmountInput
                          formik={formik}
                          name="amount"
                          type="text"
                          placeholder="0.00"
                          onChange={(e) =>
                            HandleChangeAmount(e.target.value, "amount", formik)
                          }
                          // handleAmountChange={handleAmountChange}
                        />
                        {formik.errors.amount && formik.touched.amount ? (
                          <div className="valierror">
                            {formik.errors.amount}
                          </div>
                        ) : null}
                      </div>

                      {/* Payment Method */}
                      {/* <div className="py-4 b-b tab:pb-26">
                        <h6 className="text-sm tab:text-lg mb-4">
                          Payment Method
                        </h6>
                        <div className="flex gap-2 items-center">
                          <div className="relative flex items-center">
                            <input
                              type="radio"
                              name="default-radio"
                              id="last-card"
                              className="custom-radio appearance-none cursor-pointer h-5 w-5 rounded-full border border-gray-300 checked:border-success"
                              onClick={() => setIsPaymentMethod(false)}
                            />
                          </div>
                          <label
                            htmlFor="last-card"
                            className="text-sm tab:text-base font-normal cursor-pointer m-0"
                          >
                            Last Used
                          </label>
                        </div>
                        <div className="pt-4 tab:pt-26">
                          <div className="flex items-start justify-between">
                            <div className="flex items-start gap-2.5">
                             
                              <div>
                                <img src={Bank} alt="Bank" />
                              </div>
                              <div>
                                <div className="flex flex-col gap-1">
                                  <h6 className="text-sm tab:text-base sm:inline-block hidden">
                                    Citi Bank Credit Card ****4152
                                  </h6>
                                  <h6 className="text-sm tab:text-base inline-block sm:hidden">
                                    Citi Bank
                                  </h6>
                                  <p className="sm:inline-block hidden">
                                    Craig London
                                  </p>
                                  <p className="inline-block sm:hidden">
                                    Account ending with 2235
                                  </p>
                                </div>
                                {!ispaymentMethod && (
                                  <div className="grid grid-cols-2 mt-3">
                                    <div>
                                      <label>CVV</label>
                                      <SlashInput
                                        type="number"
                                        placeholder=""
                                        name="cvv"
                                        formik={formik}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="flex items-center gap-2.5">
                              <div className="h-7 w-7 rounded-full border border-primary flex items-center justify-center cursor-pointer">
                                <AiFillEdit className="text-xs" />
                              </div>
                              <div className="h-7 w-7 rounded-full border border-primary flex items-center justify-center cursor-pointer">
                                <AiFillDelete className="text-xs" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* Payment Method */}
                      <div className="b-b py-4 tab:py-26 mb-2.5">
                        <h6 className="text-sm tab:text-lg">Payment Method</h6>
                        <div className="flex gap-10 tab:gap-32 justify-start items-center py-4 tab:pb-26">
                          {/* <div className="flex gap-2 items-center">
                            <div className="relative flex items-center">
                              <input
                                type="radio"
                                name="default-radio"
                                id="checket-upi"
                                className="custom-radio appearance-none cursor-pointer h-5 w-5 rounded-full border border-gray-300 checked:border-success"
                                onClick={() => setIsPaymentMethod("gpay")}
                              />
                            </div>
                            <label
                              htmlFor="checket-upi"
                              className="text-sm tab:text-base font-normal cursor-pointer m-0"
                            >
                              Gpay
                            </label>
                          </div> */}
                          <div className="flex gap-2 items-center">
                            <div className="relative flex items-center">
                              <input
                                type="radio"
                                name="default-radio"
                                id="checket-card"
                                className="custom-radio appearance-none cursor-pointer h-5 w-5 rounded-full border border-gray-300 checked:border-success"
                                onClick={() => setIsPaymentMethod("card")}
                                checked={ispaymentMethod === "card"}
                              />
                            </div>
                            <label
                              htmlFor="checket-card"
                              className="text-sm tab:text-base font-normal cursor-pointer m-0"
                            >
                              Credit Card
                            </label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <div className="relative flex items-center">
                              <input
                                type="radio"
                                name="default-radio"
                                id="checket-card"
                                className="custom-radio appearance-none cursor-pointer h-5 w-5 rounded-full border border-gray-300 checked:border-success"
                                onClick={() => setIsPaymentMethod("bank")}
                                checked={ispaymentMethod === "bank"}
                              />
                            </div>
                            <label
                              htmlFor="checket-card"
                              className="text-sm tab:text-base font-normal cursor-pointer m-0"
                            >
                              Bank Transfer
                            </label>
                          </div>
                        </div>

                        {/* Card Details */}
                        {ispaymentMethod === "card" && (
                          <div>
                            <h6 className="text-sm tab:text-lg">
                              Card Details
                            </h6>
                            <div className="grid tab:grid-cols-2 gap-4 pt-4">
                              <div>
                                <label className="text-sm">
                                  Card Holder Name
                                </label>
                                <SlashInput
                                  type="text"
                                  name="cardname"
                                  formik={formik}
                                  placeholder=""
                                />
                                {formik.errors.cardname &&
                                formik.touched.cardname ? (
                                  <div className="valierror">
                                    {formik.errors.cardname}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <label className="text-sm">Card Number</label>
                                <CardNumberElement
                                  className="text-input card-input"
                                  name="cardnumber"
                                  placeholder=""
                                />
                                {/* <SlashInput
                                  type="number"
                                  name="cardnumber"
                                  formik={formik}
                                  placeholder=""
                                /> */}
                              </div>
                              <div>
                                <label className="text-sm">Expiry Date</label>
                                {/* <SlashInput
                                  type="number"
                                  name="exdate"
                                  formik={formik}
                                  placeholder="mm/yyyy"
                                /> */}
                                <CardExpiryElement
                                  className="text-input card-input"
                                  name="expiry"
                                />
                              </div>
                              <div>
                                <label className="text-sm">CVV</label>
                                {/* <SlashInput
                                  type="number"
                                  name="cvv"
                                  formik={formik}
                                  placeholder="***"
                                /> */}
                                <CardCvcElement
                                  className="text-input card-input"
                                  name="cvc"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Bank Details */}
                        {ispaymentMethod === "bank" && (
                          <div>
                            <h6 className="text-sm tab:text-lg">
                              Bank Details
                            </h6>
                            <div className="grid tab:grid-cols-2 gap-4 pt-4">
                              <div>
                                <label className="text-sm">Account No.</label>
                                <SlashInput
                                  name="accountNo"
                                  type="text"
                                  placeholder=""
                                  formik={formik}
                                  onChange={(e) =>
                                    HandleChangeAmount(
                                      e.target.value,
                                      "accountNo",
                                      formik
                                    )
                                  }
                                />
                                {formik.errors.accountNo &&
                                formik.touched.accountNo ? (
                                  <div className="valierror">
                                    {formik.errors.accountNo}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <label className="text-sm">
                                  Routing Number
                                </label>
                                <SlashInput
                                  name="routingNumber"
                                  type="text"
                                  placeholder=""
                                  formik={formik}
                                  onChange={(e) =>
                                    HandleChangeAmount(
                                      e.target.value,
                                      "routingNumber",
                                      formik
                                    )
                                  }
                                />
                                {formik.errors.routingNumber &&
                                formik.touched.routingNumber ? (
                                  <div className="valierror">
                                    {formik.errors.routingNumber}
                                  </div>
                                ) : null}
                              </div>
                              <div>
                                <label className="text-sm">
                                  Account Holder Name
                                </label>
                                <SlashInput
                                  name="accountHolderName"
                                  type="text"
                                  placeholder=""
                                  formik={formik}
                                />
                                {formik.errors.accountHolderName &&
                                formik.touched.accountHolderName ? (
                                  <div className="valierror">
                                    {formik.errors.accountHolderName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="flex gap-2 items-center justify-start pt-4">
                          <input
                            type="checkbox"
                            id="checkbox-details"
                            className="custom-checkbox appearance-none cursor-pointer h-5 w-5 rounded-md border border-gray-300 checked:bg-success checked:border-success focus:outline-none"
                            name="isPreferred"
                            value={formik.values.isPreferred}
                            onChange={formik.handleChange}
                          />
                          <label
                            htmlFor="checkbox-details"
                            className="text-base font-normal cursor-pointer text-center mb-0"
                          >
                            Preferred Card
                          </label>
                        </div>
                      </div>

                      {ispaymentMethod === "card" && (
                        <div className="b-b py-4 tab:py-26 mb-2.5">
                          <h6 className="text-sm tab:text-lg py-3">
                            Shipping Address
                          </h6>
                          <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
                            <div className="">
                              <div className="">
                                <label className="text-sm">Street*</label>
                                <input
                                  // formik={formik}
                                  name="line"
                                  type="text"
                                  placeholder="Enter street "
                                  className="amount-input"
                                  value={formik.values.line} // Connect Formik's value
                                  onChange={formik.handleChange} // Connect Formik's handleChange
                                  onBlur={formik.handleBlur}
                                />
                                {formik.errors.line && formik.touched.line ? (
                                  <div className="valierror">
                                    {formik.errors.line}
                                  </div>
                                ) : null}
                              </div>
                              <div className="py-4">
                                <label className="text-sm">Country*</label>
                                <Select
                                  styles={customStyles}
                                  name="country"
                                  options={countries}
                                  value={countries.find(
                                    (country) =>
                                      country.value === formik.values.country
                                  )}
                                  onChange={(option) => {
                                    setSelectedCountry(option);
                                    formik.setFieldValue(
                                      "country",
                                      option.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  placeholder="Select Country"
                                />
                                {formik.errors.country &&
                                  formik.touched.country && (
                                    <div className="valierror">
                                      {formik.errors.country}
                                    </div>
                                  )}
                              </div>
                              <div className="">
                                <label className="text-sm">Postal Code*</label>
                                <input
                                  // formik={formik}
                                  name="postalCode"
                                  type="text"
                                  placeholder="Enter postal code"
                                  className="amount-input"
                                  onChange={(e) =>
                                    HandleChangeAmount(
                                      e.target.value,
                                      "postalCode",
                                      formik
                                    )
                                  }
                                  value={formik.values.postalCode} // Connect Formik's value
                                  // onChange={formik.handleChange} // Connect Formik's handleChange
                                  onBlur={formik.handleBlur}
                                />
                                {formik.errors.postalCode &&
                                formik.touched.postalCode ? (
                                  <div className="valierror">
                                    {formik.errors.postalCode}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div>
                              <div className="pb-4">
                                <label className="text-sm">City*</label>
                                <input
                                  formik={formik}
                                  name="city"
                                  type="text"
                                  placeholder="Enter city"
                                  className="amount-input"
                                  value={formik.values.city} // Connect Formik's value
                                  onChange={formik.handleChange} // Connect Formik's handleChange
                                  onBlur={formik.handleBlur}
                                />
                                {formik.errors.city && formik.touched.city ? (
                                  <div className="valierror">
                                    {formik.errors.city}
                                  </div>
                                ) : null}
                              </div>
                              <div className="">
                                <label className="text-sm">State*</label>
                                <Select
                                  styles={customStyles}
                                  name="state"
                                  options={states}
                                  value={states.find(
                                    (state) =>
                                      state.value === formik.values.state
                                  )}
                                  onChange={(option) => {
                                    setSelectedState(option);
                                    formik.setFieldValue("state", option.value);
                                  }}
                                  onBlur={formik.handleBlur}
                                  placeholder="Select State"
                                  isDisabled={!selectedCountry} // Disable until country is selected
                                />
                                {formik.errors.state &&
                                  formik.touched.state && (
                                    <div className="valierror">
                                      {formik.errors.state}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="b-b py-4 tab:py-26 grid tab:grid-cols-2 gap-4 tab:gap-x-4 tab:gap-y-[26px]">
                        {/* {!ispaymentMethod ? ( */}
                        <>
                          {isSubmitLoading ? (
                            <div className="btn-big btn-outline-primary h-[37px] tab:h-[49px] tab:col-span-2 flex justify-center items-center w-full">
                              <img
                                src={Loader}
                                alt="Loader"
                                className="w-7 h-7"
                              />
                            </div>
                          ) : (
                            <button
                              className="btn-big btn-outline-primary h-[37px] tab:h-[49px] px-9 w-full tab:col-span-2"
                              type="submit"
                            >
                              Pay
                            </button>
                          )}
                        </>
                        {/* ) : (
                          <>
                            {isSubmitLoading && !formik.values.isSavedCard ? (
                              <div className="btn-big btn-outline-primary h-[37px] tab:h-[49px] flex justify-center items-center w-full">
                                <img
                                  src={Loader}
                                  alt="Loader"
                                  className="w-7 h-7"
                                />
                              </div>
                            ) : (
                              <button
                                className="btn-big btn-outline-primary h-[37px] tab:h-[49px] px-9 w-full"
                                type="submit"
                                onClick={() =>
                                  formik.setFieldValue("isSavedCard", false)
                                }
                              >
                                Pay without Saving Card
                              </button>
                            )}
                            {isSubmitLoading && formik.values.isSavedCard ? (
                              <div className="btn-big btn-outline-primary h-[37px] tab:h-[49px] flex justify-center items-center w-full">
                                <img
                                  src={Loader}
                                  alt="Loader"
                                  className="w-7 h-7"
                                />
                              </div>
                            ) : (
                              <button
                                className="btn-big btn-primary h-[37px] tab:h-[49px] px-9"
                                type="submit"
                                onClick={() =>
                                  formik.setFieldValue("isSavedCard", true)
                                }
                              >
                                Save Card & Pay
                              </button>
                            )}
                          </>
                        )} */}
                      </div>
                      <div className="text-center pt-4 tab:pt-26">
                        <h6 className="text-sm font-normal pb-4 tab:pb-26">
                          We do not store any card details and never disclose
                          any user information to anyone.
                        </h6>
                        <h6 className="text-base font-normal">Powered By</h6>
                        <div className="flex justify-center items-center">
                          <img src={stripeImg} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserGiveHelpPayment;
