import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const DropdownList = ({
  data,
  name,
  width,
  type,
  setData,
  formik,
  formikName,
  reset,
  isExplore = false,
}) => {
  const menuRef = useRef();
  const menuListRef = useRef();
  const [showMe, setShowMe] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    if (isExplore) {
      setNewData(data); // Include "All" when isExplore is true
    } else {
      setNewData(data.filter((item) => item.label !== "All")); // Exclude "All"
    }
  }, [data, isExplore]);

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuListRef.current &&
      !menuListRef.current.contains(event.target)
    ) {
      if (!newData.some((item) => item.label === searchText)) {
        setSearchText(""); // Clear the input if no match
      }
      if (isExplore) {
        setNewData(data); // Reset the dropdown options (including "All")
      } else {
        setNewData(data.filter((item) => item.label !== "All")); // Reset the dropdown options (excluding "All")
      }
      setShowMe(false); // Close the dropdown
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [data, searchText, isExplore]);

  const toggleCharter = () => {
    setShowMe((prev) => !prev);
    if (!showMe) {
      if (isExplore) {
        setNewData(data); // Reset options including "All" when opening the dropdown
      } else {
        setNewData(data.filter((item) => item.label !== "All")); // Reset options excluding "All"
      }
    }
  };

  const searchData = (text) => {
    console.log("🚀 ~ handleClick ~ item:", text);
    setSearchText(text);
    setShowMe(true);
    if (type === "status") {
      setData(text);
    }

    if (text) {
      const filteredData = data
        .filter((item) => isExplore || item.label !== "All") // Include "All" only when isExplore is true
        .filter((item) =>
          item.label.toLowerCase().includes(text.toLowerCase())
        );
      setNewData(filteredData);
    } else {
      if (isExplore) {
        setNewData(data); // Reset data including "All" if input is empty
      } else {
        setNewData(data.filter((item) => item.label !== "All")); // Reset data excluding "All"
      }
    }
  };

  const handleClick = (item) => {
    setSearchText(item.label); // Set the selected item's label in the input
    if (type === "gethelp") {
      formik?.setFieldValue(formikName, item.value);
    } else {
      setData(item.value);
    }
    if (isExplore) {
      setNewData(data); // Reset the dropdown to show all options including "All"
    } else {
      setNewData(data.filter((item) => item.label !== "All")); // Reset the dropdown to show all options excluding "All"
    }
    setShowMe(false); // Close the dropdown
  };

  return (
    <div className="relative w-full">
      <div
        className="relative flex items-center"
        onClick={toggleCharter}
        style={{ width: width }}
        ref={menuRef}
      >
        <input
          type="text"
          onChange={(e) => searchData(e.target.value)}
          className="text-input"
          placeholder={name}
          value={searchText}
        />
        <div className="absolute h-9 w-9 right-0.5 cursor-pointer border border-transparent rounded-md bg-white flex items-center justify-center">
          <IoMdArrowDropdown
            className={`duration-300 ${
              showMe ? "rotate-180 text-primary" : "rotate-0"
            } h-5 w-5 text-xl cursor-pointer text-[#80929f]`}
          />
        </div>
      </div>

      {(searchText || showMe) && (
        <div
          ref={menuListRef}
          className={`absolute top-[50px] left-0 rounded-lg shadow-dropDown bg-white z-50 h-fit w-full overflow-auto max-h-[230px] ${
            showMe ? "block" : "hidden"
          }`}
          style={{
            width: width,
          }}
        >
          {newData.length > 0 ? (
            newData.map((item, index) => (
              <div
                className={`hover:bg-cgreen ${
                  searchText === item.label ? "" : ""
                }`}
                key={index}
              >
                <div
                  className="flex cursor-pointer px-1 py-3 items-center mx-2"
                  onClick={() => handleClick(item)}
                >
                  <div className="text-[14px]">{item.label}</div>
                </div>
                {newData.length - 1 !== index && (
                  <div className="border-b border-[#ebebeb] mx-2"></div>
                )}
              </div>
            ))
          ) : (
            <div className="px-4 py-3 text-center text-gray-500">
              No options found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownList;
