import React from "react";

const SlashInput = ({ type, name, placeholder, formik, path, ...props }) => {
  return (
    <input
      placeholder={placeholder}
      type={type}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      className="input-slash text-input"
      disabled={name === "email" && path && path === "edit-profile"}
      {...props}
    />
  );
};

export default SlashInput;
