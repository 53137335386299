import React, { useEffect, useState } from "react";
import Loader from "../../assets/loader.gif";
import CustomPagination from "../common/Pagination";
import { PAGE_SIZES } from "../common/constatnts";
import Toaster from "../common/toaster";
import NotificationService from "../service/notification.service";
import moment from "moment/moment";

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);

  const handleChange = (type, page) => {
    const total = totalCount / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  const getAllNotification = async () => {
    try {
      const response = await NotificationService.getAllNotification(
        page,
        pageSize
      );
      setData(response.data.data);
      setTotalCount(response.data.totalNotification);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <div className="dashboard-part flex gap-9 min-h-[calc(100vh-200px)]">
      <div className="flex flex-col gap-4 w-full">
        <h6 className="text-sm tab:text-lg">Notification</h6>

        {/* Search Part */}
        {/* <div className="dashboard-card px-4 py-5">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center pb-5 md:pb-0">
              <div className="order-2 md:order-1">
                {catelist != "" && (
                  <DropdownList
                    data={catelist}
                    name="All Categories"
                    width={"100%"}
                    type=""
                    setData={setCategory}
                  />
                )}
              </div>
              <div className="hidden md:inline-block order-3 md:order-2">
                {venList != "" && (
                  <DropdownList
                    data={venList}
                    name="All Vendors"
                    width={"100%"}
                    type=""
                    setData={setVendor}
                  />
                )}
              </div>
              <div className="hidden md:inline-block order-4 md:order-3">
                <DropdownList
                  data={AmountDropDown}
                  width={"100%"}
                  name="Amount Requested"
                  setData={setAmountRequested}
                />
              </div>
              <div className="relative order-1 md:order-4">
                <div className="relative">
                  <input
                    className=" border border-primary/15 rounded-lg py-3 ps-3 pe-8 w-full text-sm focus:outline-none focus:ring-[0.4px] focus:ring-primary h-10 overflow-hidden placeholder:text-primary/70"
                    placeholder="Search Help Requests"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <div className="absolute top-1/2 -translate-y-1/2 right-3">
                    <CgSearch className="h-4 w-4 text-primary/50" />
                  </div>
                </div>
              </div>
            </div>
            {showToggleField && (
              <div className="md:hidden grid grid-cols-1 gap-4 pb-4">
                <div className="md:hidden inline-block">
                  <DropdownList
                    data={catelist}
                    name="All Vendors"
                    width={"100%"}
                    type=""
                  />
                </div>
                <div className="md:hidden inline-block">
                  <DropdownList
                    data={catelist}
                    name="Any Status"
                    width={"100%"}
                    type=""
                  />
                </div>
              </div>
            )}
          </div>
          <div className="border-t border-primary/20 md:hidden">
            <div className="flex justify-end items-center mt-4 font-bold gap-4">
              <h6 className="text-[14px]">Advanced Search</h6>
              <label className="relative inline-flex cursor-pointer items-center m-0">
                <input
                  id="switch"
                  type="checkbox"
                  className="peer sr-only"
                  onChange={handleToggleChange}
                />
                <label for="switch" className="hidden"></label>
                <div className="peer h-5 w-10 rounded-full border bg-primary/30 after:absolute after:top-[3px] after:left-[3px] after:h-[14px] after:w-[14px] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-success peer-checked:after:translate-x-[19px]"></div>
              </label>
            </div>
          </div>
        </div> */}
        {isLoading ? (
          <div className="flex justify-center mt-3 min-h-[calc(100vh-285px)] items-center">
            <img src={Loader} alt="Loader" className="w-14 h-14" />
          </div>
        ) : (
          <>
            {/* Help Request Table */}
            {data.length > 0 ? (
              <div className="inline-block">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <strong>#</strong>
                      </th>
                      <th>
                        <strong>Title</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Created Date</strong>
                      </th>
                      <th>
                        <strong>Seen</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className="bg-white shadow-card2">
                        <td>{index + 1}</td>
                        <td>{item.title}</td>
                        <td
                          title={item.body}
                          className="max-w-[400px] text-nowrap overflow-hidden text-ellipsis"
                        >
                          {item.body}
                        </td>
                        <td>{`${moment(item.createdAt).format(
                          "DD MMM, YYYY"
                        )} at ${moment(item.createdAt).format("hh:mm A")}`}</td>
                        <td>{item.isSeen ? "Yes" : "No"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="p-4 dashboard-card">
                  <CustomPagination
                    total={totalCount}
                    page={page}
                    handleChange={handleChange}
                    dataPerPage={pageSize}
                    handleDataPerPage={handleDataPerPage}
                    PAGE_SIZES={PAGE_SIZES}
                  />
                </div>
              </div>
            ) : (
              <div className="md:flex hidden justify-center">
                <p className="mt-2 text-lg tab:text-xl">Data Not Found</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
