import React, { useState } from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { IoCloseOutline, IoEye, IoEyeOff } from "react-icons/io5";
import Input from "./Input";
import { useFormik } from "formik";
import * as yup from "yup";
import UserService from "../service/user.service";
import Toaster from "./toaster";
import VendorService from "../service/vendor.service";
import Loader from "../../assets/loader.gif";

const ChangePassword = ({ open, handleClickClose, passwordRef, type }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const validationSchema = yup.object().shape({
    oldpass: yup.string().required("Old Password is required"),
    newpass: yup
      .string()
      .required("New Password is required")
      .min(8, "New Password must be at least 8 characters long"),
    conpass: yup.string().required("Confirm New Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      oldpass: "",
      newpass: "",
      conpass: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { oldpass, newpass, conpass } = values;
      if (newpass !== conpass) {
        setIsError(true);
        return;
      }

      const body = {
        oldPassword: oldpass,
        newPassword: newpass,
      };
      setLoading(true);

      try {
        let response;
        if (type === "user") {
          response = await UserService.changePassword(body);
        } else {
          response = await VendorService.changeVendorPassword(body);
        }
        handleClickClose();
        Toaster("success", response.data.message);
      } catch (error) {
        Toaster("error", error.response.data.message);
      } finally {
        setIsError(false);
        setLoading(false);
      }
    },
  });

  return (
    <div>
      {open && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div
              className="dashboard-card overflow-hidden max-w-[448px] w-full min-w-[350px] "
              ref={passwordRef}
            >
              <div className="flex justify-between items-center b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
                <h5 className="tab:text-xl text-base">Change Password</h5>
                <div className="cursor-pointer" onClick={handleClickClose}>
                  <IoCloseOutline className="h-5 w-5" />
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="tab:p-26 p-4 flex flex-col gap-4">
                  <div>
                    <label className="flex items-center gap-2.5 sm:mt-1">
                      Old Password*
                    </label>
                    <div className="relative">
                      <Input
                        formik={formik}
                        name="oldpass"
                        type={showNewPassword ? "text" : "password"}
                        placeholder=""
                      />
                      <span
                        className="h-4 w-4 text-[#80929f] text-xl cursor-pointer absolute right-3 top-1/2 -translate-y-1/2"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      >
                        {showNewPassword ? <IoEyeOff /> : <IoEye />}
                      </span>
                    </div>
                    {formik.touched.oldpass && formik.errors.oldpass ? (
                      <div className="valierror">{formik.errors.oldpass}</div>
                    ) : null}
                  </div>
                  <div>
                    <label className="flex items-center gap-2.5 sm:mt-1">
                      New Password*
                      <span className="relative">
                        {/* <IoIosInformationCircle className="text-base text-primary/50" /> */}
                        {/* <IoIosInformationCircle
                          className="text-base text-primary/50 cursor-pointer"
                          title="Enter a secure password containing at least 8 characters, including uppercase and lowercase letters, numbers, and special symbols."
                        /> */}
                        <IoIosInformationCircle
                          className="text-base text-primary/50 cursor-pointer"
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}
                        />
                        {showTooltip && (
                          <div className="absolute left-6 top-0 bg-gray-800 text-white text-sm rounded p-2 shadow-md z-10 w-[280px]">
                            Enter a secure password containing at least 8
                            characters, including uppercase and lowercase
                            letters, numbers, and special symbols.
                          </div>
                        )}
                      </span>
                    </label>
                    <div className="relative">
                      <Input
                        formik={formik}
                        name="newpass"
                        type={showOldPassword ? "text" : "password"}
                        placeholder=""
                      />
                      <span
                        className="h-4 w-4 text-[#80929f] text-xl cursor-pointer absolute right-3 top-1/2 -translate-y-1/2"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      >
                        {showOldPassword ? <IoEyeOff /> : <IoEye />}
                      </span>
                    </div>
                    {formik.touched.newpass && formik.errors.newpass ? (
                      <div className="valierror">{formik.errors.newpass}</div>
                    ) : null}
                  </div>
                  <div>
                    <label className="flex items-center gap-2.5 sm:mt-1">
                      Confirm New Password*
                    </label>
                    <div className="relative">
                      <Input
                        formik={formik}
                        name="conpass"
                        type={showConfirmNewPassword ? "text" : "password"}
                        placeholder=""
                      />
                      <span
                        className="h-4 w-4 text-[#80929f] text-xl cursor-pointer absolute right-3 top-1/2 -translate-y-1/2"
                        onClick={() =>
                          setShowConfirmNewPassword(!showConfirmNewPassword)
                        }
                      >
                        {showConfirmNewPassword ? <IoEyeOff /> : <IoEye />}
                      </span>
                    </div>
                    {formik.touched.conpass && formik.errors.conpass ? (
                      <div className="valierror">{formik.errors.conpass}</div>
                    ) : null}
                    {isError ? (
                      <div className="valierror">Password Not matched!!</div>
                    ) : null}
                  </div>
                </div>
                <div className=" b-b border-primary/20 mb-26"></div>
                <div className="text-end tab:py-5 tab:px-26 py-4 px-4 flex justify-end">
                  {loading ? (
                    <button
                      className="btn-big btn-primary h-[37px] w-[98px] tab:h-[49px] tab:w-[130px] flex justify-center items-center"
                      type="submit"
                    >
                      <img src={Loader} className="h-7 w-7" />
                    </button>
                  ) : (
                    <button type="submit" className="btn-big btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
