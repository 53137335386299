import React from "react";
import Input from "../../common/Input";
import { IoCloseOutline } from "react-icons/io5";
import stripe from "../../../assets/svg/stripe.svg";
import Loader from "../../../assets/loader.gif";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

const AddGiveHelpCard = ({
  handleClickClose,
  open,
  helpRef,
  cardFormik,
  cardIsLoading,
}) => {
  return (
    <div>
      {open && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <form
              className="dashboard-card overflow-hidden max-w-[652px] w-full min-w-[350px]"
              ref={helpRef}
              onSubmit={cardFormik.handleSubmit}
            >
              <div className="flex justify-between items-center b-b border-primary/20 tab:px-26 px-4 py-4 tab:py-5">
                <h5 className="tab:text-xl text-base">Add Card</h5>
                <div className="cursor-pointer" onClick={handleClickClose}>
                  <IoCloseOutline className="h-5 w-5" />
                </div>
              </div>
              <div className="tab:p-26 p-4 flex flex-col gap-4">
                <div>
                  <label>Account Holder Name</label>
                  <Input
                    name="holdername"
                    type="text"
                    placeholder=""
                    formik={cardFormik}
                  />
                  {cardFormik.touched.holdername &&
                  cardFormik.errors.holdername ? (
                    <div className="valierror">
                      {cardFormik.errors.holdername}
                    </div>
                  ) : null}
                </div>
                <div>
                  <label>Card Number</label>
                  <CardNumberElement
                    className="text-input card-input"
                    name="cardnumber"
                    placeholder=""
                  />
                  {cardFormik.touched.cardnumber &&
                  cardFormik.errors.cardnumber ? (
                    <div className="valierror">
                      {cardFormik.errors.cardnumber}
                    </div>
                  ) : null}
                </div>
                <div className="grid grid-cols-1 tab:grid-cols-2 gap-4">
                  <div>
                    <label>Expiry</label>
                    <CardExpiryElement
                      className="text-input card-input"
                      name="expiry"
                    />
                  </div>
                  <div>
                    <label>CVC</label>
                    <CardCvcElement
                      className="text-input card-input"
                      name="cvc"
                    />
                  </div>
                </div>
                <div className="flex justify-start items-center gap-3">
                  <input
                    type="checkbox"
                    id="checkbox-details"
                    className="custom-checkbox appearance-none cursor-pointer h-5 w-5 rounded-md border border-gray-300 checked:bg-success checked:border-success focus:outline-none focus:ring-2 focus:ring-success focus:ring-offset-2"
                    name="isPreferred"
                    value={cardFormik.values.isPreferred}
                    onChange={cardFormik.handleChange}
                  />
                  <lable>Preferred Account</lable>
                </div>
                <div className="b-b border-primary/20"></div>
                <div>
                  <p className="text-center">
                    We do not store any card details and never disclose any user
                    information to anyone.
                  </p>
                </div>
                <div className="text-center">
                  <h6 className="text-base font-normal">Powered By</h6>
                  <div className="flex justify-center items-center">
                    <img src={stripe} />
                  </div>
                </div>
              </div>
              <div className=" b-b border-primary/20 mb-26"></div>
              {/* <CardElement /> */}
              <div className="flex justify-end tab:py-5 tab:px-26 py-4 px-4">
                {cardIsLoading ? (
                  <button
                    className="btn-big btn-primary h-[37px] w-[98px] tab:h-[49px] tab:w-[130px] flex justify-center items-center"
                    type="submit"
                  >
                    <img src={Loader} className="h-7 w-7" />
                  </button>
                ) : (
                  <button
                    className="btn-big btn-primary h-[37px] w-[98px] tab:h-[49px] tab:w-[130px]"
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddGiveHelpCard;
