import React from "react";

const Input = ({ type, name, placeholder, formik, ...props }) => {
  return (
    <input
      placeholder={placeholder}
      type={type}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      className="text-input"
      {...props}
    />
  );
};

export default Input;
