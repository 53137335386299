import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { MdAttachFile } from "react-icons/md";
import SlashInput from "../common/SlashInput";
import { FaCalendar } from "react-icons/fa";
import CategoryService from "../service/category.service";
import Toaster from "../common/toaster";
import DropdownList from "../common/dropdown";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import moment from "moment";
import HelpService from "../service/help.service";
import Loader from "../../assets/loader.gif";
import { formatFileSize, HandleChangeAmount } from "../common/Common";

const VendorHelpRequest = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [reset, setReset] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [selectedBill, setSelectedBill] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    categoryId: yup.string().required("Category Name is Required"),
    // amount: yup.string().required("Amount is Required"),
    amount: yup
      .number()
      .required("Amount is required")
      .min(1, "Amount must be greater than 0")
      .max(99999, "Request Amount cannot exceed 5 digits")
      .test(
        "maxDigits",
        "Request Amount cannot exceed 5 digits",
        (value) => String(Math.floor(Math.abs(value))).length <= 5
      ),

    invoiceDate: yup.string().required("Invoice date is Required"),
    customerName: yup.string().required("Custom Name is Required"),
    account: yup.string().required("Account is Required"),
    phoneNumber: yup.number().required("Account Phone Number is Required"),
    email: yup.string().required("Customer Email is Required"),
    invoiceId: yup.string().required("Invoice ID is Required"),
    bill: yup.mixed().required("Bill is Required"),
    image: yup.mixed().required("Image is Required"),
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const {
      categoryId,
      amount,
      invoiceDate,
      customerName,
      account,
      phoneNumber,
      email,
      invoiceId,
      bill,
      image,
    } = values;
    try {
      const formData = new FormData();
      formData.append("categoryId", categoryId);
      formData.append("amount", amount);
      formData.append("invoiceDate", invoiceDate);
      formData.append("customerName", customerName);
      formData.append("account", account);
      formData.append("phoneNumber", phoneNumber);
      formData.append("email", email);
      formData.append("invoiceId", invoiceId);
      formData.append("bill", bill);
      formData.append("image", image);

      const response = await HelpService.vendorCreateHelpRequest(formData);
      setReset(true);
      formik.resetForm();
      setSelectedBill("");
      setSelectedImage("");
      Toaster("success", response.data.message);
      setStartDate("");
      setTimeout(() => {
        setReset(false);
      }, 500);
    } catch (error) {
      Toaster("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      categoryId: "",
      amount: "",
      invoiceDate: "",
      customerName: "",
      account: "",
      phoneNumber: "",
      email: "",
      invoiceId: "",
      bill: null,
      image: null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const processBills = (file) => {
    if (file?.name?.endsWith(".pdf")) {
      formik.setFieldValue("bill", file);
      setSelectedBill(file);
    } else {
      Toaster("error", "File is not supported! Please Upload PDF File");
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];

    if (type === "image") {
      processImage(files);
    } else {
      processBills(files);
    }
  };

  const handleImageChange = (e) => {
    const files = e.target.files[0];
    processImage(files);
  };

  const processImage = (file) => {
    formik.setFieldValue("image", file);
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleBillChange = (e) => {
    const files = e.target.files[0];
    processBills(files);
  };

  const handleSelectDate = (e) => {
    formik.setFieldValue("invoiceDate", moment(e).format("DD/MM/YYYY"));
    setStartDate(e);
  };

  const getAllCategory = async () => {
    try {
      const reponse = await CategoryService.getAllCategory();
      const newObject = [
        {
          name: "All",
          _id: "",
        },
        ...reponse.data.data,
      ];
      setCategoryData(newObject);
    } catch (error) {
      Toaster("error", error.response.data.message);
    }
  };

  const catelist = [
    ...categoryData.map((cat) => ({
      label: cat.name,
      value: cat._id,
    })),
  ];

  useEffect(() => {
    getAllCategory();
  }, []);

  /* useEffect(() => {
    const payrroString = localStorage.getItem("payyro-store");
    const payyroStore = JSON.parse(payrroString);
    if (payyroStore) {
      formik.setFieldValue("customerName", payyroStore?.userName || "");
      // formik.setFieldValue("address", payyroStore?.address || "");
      formik.setFieldValue("email", payyroStore?.email || "");
    }
  }, [categoryData]); */

  return (
    <div className="dashboard-part flex gap-9 min-h-[calc(100vh-200px)]">
      <div>
        <div className="dashboard-card rounded-10 w-full tab:max-w-[758px]">
          <h6 className="tab:text-base text-sm tab:py-5 tab:px-26 py-4 px-4 b-b border-primary/20">
            Create Help Request and Submit to Customer for Publication
          </h6>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="tab:p-26 p-4 b-b border-primary/20">
                <div className="grid grid-cols-1 tab:grid-cols-2 gap-4">
                  <div>
                    <label>Customer Name*</label>
                    <SlashInput
                      name="customerName"
                      type="text"
                      placeholder=""
                      formik={formik}
                    />
                    {formik.touched.customerName &&
                    formik.errors.customerName ? (
                      <div className="valierror">
                        {formik.errors.customerName}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <label>Customer Email*</label>
                    <SlashInput
                      name="email"
                      type="email"
                      placeholder=""
                      formik={formik}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="valierror">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <label>Customer Account*</label>
                    <SlashInput
                      name="account"
                      type="text"
                      placeholder=""
                      formik={formik}
                      onChange={(e) =>
                        HandleChangeAmount(e.target.value, "account", formik)
                      }
                    />
                    {formik.touched.account && formik.errors.account ? (
                      <div className="valierror">{formik.errors.account}</div>
                    ) : null}
                  </div>
                  <div>
                    <label>Account Phone No.*</label>
                    <SlashInput
                      name="phoneNumber"
                      type="text"
                      placeholder=""
                      formik={formik}
                      onChange={(e) =>
                        HandleChangeAmount(
                          e.target.value,
                          "phoneNumber",
                          formik
                        )
                      }
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <div className="valierror">
                        {formik.errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-1 tab:grid-cols-2 gap-4">
                  <div className="my-4">
                    <label>Invoice Amount*</label>
                    <SlashInput
                      name="amount"
                      type="text"
                      formik={formik}
                      placeholder=""
                      onChange={(e) =>
                        HandleChangeAmount(e.target.value, "amount", formik)
                      }
                    />
                    {formik.touched.amount && formik.errors.amount ? (
                      <div className="valierror">{formik.errors.amount}</div>
                    ) : null}
                  </div>
                  <div className="my-4">
                    <label>Invoice #*</label>
                    <SlashInput
                      name="invoiceId"
                      type="text"
                      placeholder=""
                      formik={formik}
                    />
                    {formik.touched.invoiceId && formik.errors.invoiceId ? (
                      <div className="valierror">{formik.errors.invoiceId}</div>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-1 tab:grid-cols-2 gap-4">
                  <div>
                    <label>Invoice Date*</label>
                    <div className="p-0 border border-primary/15 rounded-lg px-3 relative flex items-center h-10 w-full bg-white cursor-pointer">
                      <DatePicker
                        placeholderText="Help Requested Date"
                        selected={startDate}
                        onChange={handleSelectDate}
                        className="border-0 focus:outline-none text-primary/70 text-sm bg-transparent w-full"
                        dateFormat="dd/MM/yyyy"
                      />
                      <FaCalendar className="h-4 w-4 absolute right-5 text-primary/50" />
                    </div>
                    {formik.touched.invoiceDate && formik.errors.invoiceDate ? (
                      <div className="valierror">
                        {formik.errors.invoiceDate}
                      </div>
                    ) : null}
                  </div>
                  {catelist != "" && (
                    <div>
                      <label>Category Name</label>
                      <DropdownList
                        data={catelist}
                        name="Select Category"
                        width={"100%"}
                        type="gethelp"
                        formik={formik}
                        formikName="categoryId"
                        reset={reset}
                      />
                      {formik.touched.categoryId && formik.errors.categoryId ? (
                        <div className="valierror">
                          {formik.errors.categoryId}
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 tab:grid-cols-2 gap-4">
                  <div>
                    <div className="relative">
                      <label
                        className="border-2 mt-6 border-dashed border-primary/20 rounded-md w-full h-20 tab:h-[110px] flex items-center justify-center gap-1.5 cursor-pointer"
                        htmlFor="image-upload"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e, "image")}
                      >
                        <MdAttachFile />
                        <h6 className="text-sm">Upload a Image</h6>
                      </label>
                      <input
                        id="image-upload"
                        type="file"
                        className="hidden"
                        onChange={handleImageChange}
                        name="image"
                        accept="image/*"
                      />
                      {selectedImage && (
                        <div className="flex justify-center border border-primary/20 w-full rounded-lg p-4 mt-4 mb-3 relative">
                          <img
                            src={selectedImage}
                            alt="selectedImage"
                            className="max-h-[250px] object-cover w-full rounded-md"
                          />
                          <div className="absolute right-1 top-1">
                            <IoMdCloseCircle
                              className="h-4 w-4 text-primary cursor-pointer"
                              onClick={() => {
                                setSelectedImage("");
                                formik.setFieldValue("image", "");
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {formik.touched.image && formik.errors.image ? (
                        <div className="valierror">{formik.errors.image}</div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div className="relative">
                      <label
                        className="border-2 mt-6 border-dashed border-primary/20 rounded-md w-full h-20 tab:h-[110px] flex items-center justify-center gap-1.5 cursor-pointer"
                        htmlFor="bill-upload"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDrop(e)}
                      >
                        <MdAttachFile />
                        <h6 className="text-sm">Upload a copy of the bill</h6>
                      </label>
                      <input
                        id="bill-upload"
                        type="file"
                        className="hidden"
                        onChange={handleBillChange}
                        name="bill"
                        accept=".pdf,.PDF"
                      />
                      {selectedBill && (
                        <div className="flex justify-between items-start border border-primary/20 w-full rounded-lg p-2.5 mt-4 mb-3">
                          <div className="flex justify-start items-start gap-2.5">
                            <div className={"text-red-500 text-2xl"}>
                              <BsFileEarmarkPdfFill />
                            </div>
                            <div>
                              <h6 className="text-base break-all">
                                {selectedBill.name}
                              </h6>
                              <p className="text-sm">
                                {formatFileSize(selectedBill.size)}
                              </p>
                            </div>
                          </div>
                          <div>
                            <IoMdCloseCircle
                              className="h-4 w-4 text-primary/40 cursor-pointer"
                              onClick={() => {
                                setSelectedBill("");
                                formik.setFieldValue("bill", "");
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {formik.touched.bill && formik.errors.bill ? (
                        <div className="valierror">{formik.errors.bill}</div>
                      ) : null}
                    </div>

                    <p className="text-center">
                      *We do not store any card details and never disclose any
                      user information to anyone.
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab:py-5 tab:px-26 py-4 px-4 text-end flex justify-end">
                {isLoading ? (
                  <button
                    type="submit"
                    className="btn-big btn-primary !w-[204.8px] tab:!w-[216.8px] items-center justify-center flex !h-[37.16px] tab:!h-[49.16px]"
                  >
                    <img src={Loader} alt="Loader" className="w-8 h-8" />
                  </button>
                ) : (
                  <button type="submit" className="btn-big btn-primary">
                    Submit Help Request
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorHelpRequest;
