import React from "react";
import "../../PaymentReceipt.css";
import moment from "moment";
import Logo from "../../assets/svg/Logo.svg";

const PaymentReceipt = ({ rowData }) => {
  const paymentDate = moment
    .unix(
      JSON.parse(rowData?.response)?.data?.object?.charges?.data[0]?.created
    )
    .format("DD MMM, YYYY");

  const paymentTime = moment
    .unix(
      JSON.parse(rowData?.response)?.data?.object?.charges?.data[0]?.created
    )
    .format("hh:mm A");

  const description = JSON.parse(rowData?.response)?.data?.object?.description;

  return (
    <div className="payment-slip p-10" id="payment-slip">
      <div className="flex justify-center mb-10">
        <img src={Logo} alt="Logo" />
      </div>
      <table className="border-spacing-0 text-sm mt-5">
        <tr>
          <td className="w-24 font-bold">Invoice Id :</td>
          <td className="font-bold">{rowData.invoiceId}</td>
        </tr>
        <tr>
          <td className="w-24">Date :</td>
          <td>
            {paymentDate} at {paymentTime}
          </td>
        </tr>
      </table>

      <div className="flex justify-evenly mt-5">
        <table className="border-spacing-0 w-fit text-sm">
          <tr>
            <td className="text-start font-bold">Bill To</td>
          </tr>
          <tr>
            <td>{rowData.user.name || rowData.user.firstName}</td>
          </tr>
          <tr>
            <td>{rowData.user.email}</td>
          </tr>
        </table>
      </div>

      <table className="text-sm mt-5">
        <tr className="mb-2">
          <td className="font-bold">Description</td>
          <td className="text-end font-bold">Amount</td>
        </tr>

        <tr className="border-t border-[#2b2b2b]">
          <td>
            {description
              ? description
              : `Donate to ${rowData.user.name || rowData.user.firstName}`}
          </td>
          <td className="text-end">${rowData.amount}</td>
        </tr>

        <tr>
          <td></td>
          <td className="flex justify-between font-bold border-t">
            <p>Total:</p>
            <p>${rowData.amount} USD</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PaymentReceipt;
